import React, { createContext, useContext, useState, useCallback, useEffect } from "react"
import Popup from "../layout/popup/layout/popup.layout"

const PopupContext = createContext()

export const usePopup = () => useContext( PopupContext )

export const PopupProvider = ( { children } ) => {
    const [ isPopupVisible, setIsPopupVisible ] = useState( false )
    const [ popupType, setPopupType ] = useState( "" )
    const [ popupMessage, setPopupMessage ] = useState( "" )

    const hidePopup = useCallback( () => {
        setIsPopupVisible( false )
    }, [] )

    const showPopup = useCallback( ( type, message ) => {
        setPopupType( type )
        setPopupMessage( message )
        setIsPopupVisible( true )
    }, [] )

    useEffect(() => {
        let timer
        if ( isPopupVisible ) {
            timer = setTimeout(() => {
                setIsPopupVisible( false )
            }, 6000 )
        }
        return () => clearTimeout( timer )
    }, [ isPopupVisible ] )

    return (
        <PopupContext.Provider value={ { showPopup, hidePopup } }>
            { children }
            { isPopupVisible && <Popup type={ popupType } message={ popupMessage } />}
        </PopupContext.Provider>
    )
}