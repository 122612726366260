import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ModalProvider } from './contexts/modal.context'
import { PopupProvider } from './contexts/popup.context'
import { LanguageProvider } from './contexts/language.context'

const root = ReactDOM.createRoot( document.getElementById( 'root' ) )
root.render(
  <React.StrictMode>

    <LanguageProvider>
    
      <HelmetProvider>

          <PopupProvider>

            <ModalProvider>

              <Router>

                <App />
              
              </Router>

            </ModalProvider>

          </PopupProvider>
        
      </HelmetProvider>

    </LanguageProvider>

  </React.StrictMode>
)

reportWebVitals()
