import { useState, useEffect, Fragment } from "react"
import { Link } from "react-router-dom"
import useLocalStorage from "../../../hooks/storage.hook"
import useFetchData from "../../../hooks/fetch.hook"
import Button from "../../../components/button/layout/button.component"
import Spinner from "../../../components/spinner/layout/spinner.component"

const CookieConsentPopup = ( { language } ) => {
    const [ consent, setConsent ] = useLocalStorage( 'cookieConsent', '' )
    const [ isVisible, setIsVisible ] = useState( consent !== 'given' )
    const [ isExiting, setIsExiting ] = useState( false )

    const documentPath = `layout/cookies/languages/${ language }`

    const { data: documentData, isLoading: documentLoading, error: documentError } = useFetchData( documentPath, 'document' )

    useEffect( () => {
        if ( consent === 'rejected' ) {
            const timer = setTimeout( () => {
                setIsVisible( true )
                setConsent( '' )
            }, 300000 )

            return () => clearTimeout( timer )
        }
    }, [ consent, setConsent ] )

    const handleConsent = () => {
        setIsExiting( true )

        setTimeout( () => {
            setConsent( 'given' )
            setIsVisible( false )
            setIsExiting( false )
        }, 500 )

    }

    const handleReject = () => {
        setIsExiting( true )

        setTimeout( () => {
            setConsent( 'rejected' )
            setIsVisible( false )
            setIsExiting( false )
        }, 500 )
    }

    if ( !isVisible && !isExiting ) return null

    return (

        <div
            className={ `cookie__concent__popup ${ isExiting ? "slide__out" : "slide__in" }` }
        >

            { documentError && (

                <p>Error fetching data: { documentError.message }</p>

            ) }

            { documentLoading && ( <Spinner /> ) }

            { documentData && 

                (

                    <Fragment>

                        <p
                            className={ "cookie__concent__popup__text" }
                        >

                            { documentData.text } <Link className={ "cookie__concent__popup__text__link" } to={ `/${ language }/privacy-policy` }>{ documentData.link }</Link>.
                            
                        </p>

                        <div
                            className={ "cookie__concent__popup__control__container" }
                        >
            
                            <Button
                                buttonType={ "btn__primary" }
                                buttonSize={ "btn__medium" }
                                onClick={ handleConsent }
                                style={ { flex: 1 } }   
                            >
                            
                                { documentData.accept }
                                
                            </Button>
            
                            <Button
                                buttonType={ "btn__ghost" }
                                onClick={ handleReject }
                                style={ { flex: 1 } }
                            >
                                
                                { documentData.reject }
                                
                            </Button>
                        
                        </div>

                    </Fragment>

                )
                
            }

        </div>
    )

}

export default CookieConsentPopup