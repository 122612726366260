import { useState } from 'react'

const useLocalStorage = ( key, initialValue ) => {

  const storedValue = localStorage.getItem( key )
  const initial = storedValue || initialValue

  const [ value, setValue ] = useState( initial )

  const setStoredValue = ( newValue ) => {
    setValue( newValue )
    localStorage.setItem( key, newValue )
  }

  return [ value, setStoredValue ]
}

export default useLocalStorage