import { useState, useRef, forwardRef } from 'react'
import useIntersectionObserver from "../../hooks/intersection.observer.hook"

const ImageLoader = forwardRef( ( { src, alt, className, ...props }, ref ) => {
  const [ loaded, setLoaded ] = useState( false )
  const imgRef = useRef( null )

  const combinedRef = ref || imgRef

  useIntersectionObserver( combinedRef, {}, () => setLoaded( true ) )

  return (
    <img
      ref={ combinedRef }
      src={ loaded ? src : undefined }
      alt={ alt }
      className={ `${ className } ${ loaded ? 'loaded' : 'loading' }` }
      style={ { opacity: loaded ? 1 : 0, transition: 'opacity 1s ease' } }
      { ...props }
    />
  )
} )

export default ImageLoader
