const Popup = ( { type, message } ) => {

    return (

        <div 
            className={ `popup ${ type }` }
        >

            <div
                className="popup__content"
            >

                <span
                    className="popup__content__message"
                >
                    
                    { message }
                    
                </span>

            </div>

        </div>

    )

}

export default Popup
