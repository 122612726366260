import { useState } from 'react'

const useMailchimp = () => {
    const [ isSending, setIsSending ] = useState( false )
    const [ error, setError ] = useState( null )

    const sendDataToMailchimp = async ( formData ) => {
        setIsSending( true )
        setError( null )

        try {
            const response = await fetch( 'https://us-central1-crade-281c9.cloudfunctions.net/addSubscriber', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( formData ),
            } )

            if ( !response.ok ) {
                const contentType = response.headers.get( 'content-type' )
                let errorBody

                if ( contentType && contentType.includes( 'application/json' ) ) {
                    errorBody = await response.json()
                } else {
                    errorBody = await response.text()
                }

                throw new Error( `Server responded with ${ response.status }: ${ response.statusText }. Error details: ${ JSON.stringify( errorBody ) }` )
            }

            return true
        } catch ( error ) {
            setError( error )
            return false
        } finally {
            setIsSending( false )
        }
    }

    return { sendDataToMailchimp, isSending, error }
}

export default useMailchimp
