import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect( () => {
    const mainElement = document.querySelector( 'main' )
    if ( mainElement ) {
      mainElement.scrollTop = 0
    }
  }, [ pathname ] )

  return null
}

export default ScrollToTop
