import { useEffect, useRef } from 'react'

const useIntersectionObserver = ( ref, options, callback ) => {
  
  const callbackRef = useRef( callback )
  useEffect( () => {
    callbackRef.current = callback
  }, [ callback ] )

  useEffect( () => {
    const observer = new IntersectionObserver( ( entries ) => {
      entries.forEach(entry => {
        if ( entry.isIntersecting ) {
          callbackRef.current()
        }
      } )
    }, options )

    const currentRef = ref.current
    if ( currentRef ) {
      observer.observe( currentRef )
    }

    return () => {
      if ( currentRef ) {
        observer.unobserve( currentRef )
      }
      observer.disconnect()
    }
  }, [ ref, options ] )
}


export default useIntersectionObserver