export const routePaths = {
    home: {
        en: "",
        fr: "",
        nl: "",
    },
    about: {
        en: "about",
        fr: "a-propos",
        nl: "over",
    },
    stories: {
        en: "stories",
        fr: "histoires",
        nl: "verhalen",
    },
    services: {
        en: "services",
        fr: "services",
        nl: "diensten",
    },
    carpentry: {
        en: "services/carpentry",
        fr: "services/menuiserie",
        nl: "diensten/timmerwerk",
    },
    furniture: {
        en: "services/furniture",
        fr: "services/meubles",
        nl: "diensten/meubilair",
    },
    interiors: {
        en: "services/interiors",
        fr: "services/interieurs",
        nl: "diensten/interieurs",
    },
    visuals: {
        en: "services/visuals",
        fr: "services/visuels",
        nl: "diensten/visuals",
    },
    storiesCarpentry: {
        en: "stories/carpentry",
        fr: "histoires/menuiserie",
        nl: "verhalen/timmerwerk",
    },
    storiesFurniture: {
        en: "stories/furniture",
        fr: "histoires/meubles",
        nl: "verhalen/meubilair",
    },
    storiesInteriors: {
        en: "stories/interiors",
        fr: "histoires/interieurs",
        nl: "verhalen/interieurs",
    },
    storiesVisuals: {
        en: "stories/visuals",
        fr: "histoires/visuels",
        nl: "verhalen/visuals",
    },
    blog: {
        en: "blog",
        fr: "blog",
        nl: "blog",
    },
    blogCarpentry: {
        en: "blog/carpentry",
        fr: "blog/menuiserie",
        nl: "blog/timmerwerk",
    },
    blogFurniture: {
        en: "blog/furniture",
        fr: "blog/meubles",
        nl: "blog/meubilair",
    },
    blogInteriors: {
        en: "blog/interiors",
        fr: "blog/interieurs",
        nl: "blog/interieurs",
    },
    blogVisuals: {
        en: "blog/visuals",
        fr: "blog/visuels",
        nl: "blog/visuals",
    },
    contact: {
        en: "contact",
        fr: "contact",
        nl: "contact",
    },
    terms: {
        en: "terms-of-use",
        fr: "conditions-dutilisation",
        nl: "gebruiksvoorwaarden",
    },
    privacy: {
        en: "privacy-policy",
        fr: "politique-de-confidentialite",
        nl: "privacybeleid",
    }
}